<template>
	<v-flex v-t="'router.autologin-callback.page_title'" />
</template>

<script>
import AuthModuleGuard from '@/mixins/ModulesGuards/Auth/AuthModuleGuard'

export default {
	name: 'AutoLoginCallBack',
	mixins: [AuthModuleGuard],
	props: {
		redirect: {
			default: null,
			required: false,
			type: String
		},
		refreshToken: {
			default: true,
			required: false,
			type: Boolean
		},
		token: {
			default: null,
			required: false,
			type: String
		}
	},
	mounted: function () {
		if (!this.token) {
			this.redirectToSignin()
		} else {
			this.rememberToken()
			this.service
				.autoLogin(this.refreshToken)
				.then(() => {
					if (this.service.getIsAuthenticated()) {
						if (this.$route.query?.redirect) {
							this.redirectToSpecifiedUrl()
						} else {
							this.redirectToHome()
						}
					} else {
						this.redirectToSignin()
					}
				})
				.catch(() => {
					this.redirectToSignin()
				})
		}
		
	},
	methods: {
		redirectToSignin: function () {
			const redirect = { name: 'signin' }
			if (this.redirect) {
				redirect.query = {
					redirect: this.redirect
				}
			}
			this.appService.goTo(redirect)
		},
		redirectToHome: function () {
			this.appService.goTo('home')
		},
		redirectToSpecifiedUrl: function () {
			let redirect
			const queryRedirect = decodeURIComponent(this.$route.query.redirect)
			if (queryRedirect.includes('/')) {
				redirect = {
					path: queryRedirect
				}
			} else {
				redirect = {
					name: queryRedirect
				}
			}
			this.appService.goTo(redirect)
		},
		onSuccessRedirect: function () {

		},
		rememberToken: function () {
			localStorage.setItem('token', this.token)
		}
	}
}
</script>
